import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        // 在这里定义共享的状态
    },
    mutations: {
        // 在这里定义修改状态的方法
        handleClick(state) {
            // 这里定义公共点击方法的逻辑
        },
        jopkefu() {
            window.open("https://www11c1.53kf.com/webCompany.php?kf_sign=DI0MjMTY4MkzODE1NTc0NTYwMDMzMDAzNzIxNjgzNTM=&arg=10168353&style=1&guest_id=12950638033002&language=zh-cn&charset=gbk&referer=http%3A%2F%2Fpublic.rossoarts.com%2F&keyword=http%3A%2F%2Flocalhost%3A8081%2F&tpl=crystal_blue&uid=392070b4b477c77869f6a6a47a1dfee8&is_group=&timeStamp=1680242953439&ucust_id=", '_blank')
        }


    },
    actions: {
        // 在这里定义触发 mutations 的动作
    }
});

export default store;