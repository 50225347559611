import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';

import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/animate.css"
import ElementUI from 'element-ui';
import MetaInfo from 'vue-meta-info'

Vue.use(MetaInfo)
Vue.use(ElementUI);
// Vue.use(animate);
// Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: function (h) { return h(App) }
}).$mount('#app')


// 缩小屏幕 webpack报错
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}